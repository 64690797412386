import Mixins from "../../Mixins.js";

export default {
  name: "DetailSavingChartOfAccount",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        savingId: "",
      },
      property: {
        modal: {
          showModalEditSavingChartOfAccount: false,
        },
        listElement: {
          savingChartOfAccount: {
            rows: 0,
            perPage: 5,
            currentPage: 1,
            downloading: false,
            message: "",
          },
        },
        animation: {
          addSaving: {
            isLoading: false,
          },
          addSavingChartOfAccount: {
            isLoading: false,
          },
          editSavingChartOfAccount: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        add: {
          savingId: "",
          chartOfAccountId: "",
          productLedgerId: "",
          isActive: false,
          productCategoryName: "",
          akunLedgerDeskripsi: "",
          mutationPosition: "",
          pairChartOfAccountId: "",
          pairMutationPosition: "",
          transactionCodeId: "",
          pairType: "",
          isFixedCost: false,
          fixedCost: 0,
        },
        edit: {
          savingId: "",
          chartOfAccountId: "",
          productLedgerId: "",
          isActive: false,
          productCategoryName: "",
          akunLedgerDeskripsi: "",
          mutationPosition: "",
          pairChartOfAccountId: "",
          pairMutationPosition: "",
          transactionCodeId: "",
          pairType: "",
          createdByUserCode: "",
          createdByUserName: "",
          createdDate: "",
          updatedDate: "",
          updatedByUserCode: "",
          updatedByUserName: "",
          authorizedDate: "",
          authorizedByUserCode: "",
          authorizedByUserName: "",
          isFixedCost: false,
          fixedCost: 0,
        },
      },
      identifier: {
        savingChartOfAccountSelected: "",
      },
      options: {
        parameterLedgerLayanan: [],
        parameterProduk: [],
        akunLedger: [],
        posisiMutasi: [],
        transactionCode: [],
      },
      table: {
        data: {
          savingChartOfAccount: [],
        },
      },
    };
  },
  methods: {
    changePairTypeAddSavingChartOfAccount() {
      if (this.dataForm.add.pairType === "SAVING") {
        this.dataForm.add.pairChartOfAccountId = "";
      }
    },
    changePairTypeEditSavingChartOfAccount() {
      if (this.dataForm.edit.pairType === "SAVING") {
        this.dataForm.edit.pairChartOfAccountId = "";
      }
    },
    getIdentityFromMixin() {
      this.identity.savingId = this.mixin_data_identifier_saving_id;
    },
    async getProductCategoryNameSavingChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_PRODUCT_CATEGORY"
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            if (
              i.productCategoryId ===
              this.fetchAppSession("@vue-session/config-bundle")
                .VUE_APP_PRODUCT_CATEGORY_SAVING
            ) {
              this.dataForm.add.productCategoryName = i.productCategoryName;
              this.dataForm.edit.productCategoryName = i.productCategoryName;
            }
          });
        }
      } catch (error) {}
    },
    async getReferenceProductLedger() {
      try {
        const resp = await this.$store.dispatch(
          "GET_PRODUCT_LEDGER_BY_PRODUCT_CATEGORY_ID",
          {
            productCategoryId: this.fetchAppSession(
              "@vue-session/config-bundle"
            ).VUE_APP_PRODUCT_CATEGORY_SAVING,
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((i) => {
            const value = i.productLedgerId;
            const text = i.productLedgerName;
            this.options.parameterProduk.push({ value, text });
          });
        }
      } catch (error) {}
    },
    clearTableSavingChartOfAccount() {
      this.table.data.savingChartOfAccount = [];
      this.property.listElement.savingChartOfAccount.rows = 0;
      this.property.listElement.savingChartOfAccount.currentPage = 1;
    },
    async getReferenceChartOfAccount() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              description: "",
              page: 0,
            },
            url: "chart-of-account",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            const description = i.description;
            this.options.akunLedger.push({ text, value, description });
          });
        }
      } catch (error) {}
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              page: 0,
              name: "",
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            this.options.transactionCode.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getSavingChartOfAccount() {
      this.clearTableSavingChartOfAccount();
      this.property.listElement.savingChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-chart-of-account",
          payload: {
            savingId: this.identity.savingId ? this.identity.savingId : "",
            page: 0,
            size: this.property.listElement.savingChartOfAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.property.listElement.savingChartOfAccount.rows =
              resp.data.data.totalElements;
            this.table.data.savingChartOfAccount = resp.data.data.content;
          } else {
            this.property.listElement.savingChartOfAccount.message =
              resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSavingChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.savingChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    handleErrorSavingChartOfAccount(error) {
      console.log(error.response);
      this.table.data.savingChartOfAccount = [];
      this.property.listElement.savingChartOfAccount.rows = 0;
      this.property.listElement.savingChartOfAccount.currentPage = 1;
      this.property.listElement.savingChartOfAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    async addSavingChartOfAccount() {
      const payload = {
        savingId: this.identity.savingId,
        chartOfAccountId: this.dataForm.add.chartOfAccountId,
        productLedgerId: this.dataForm.add.productLedgerId,
        mutationPosition: this.dataForm.add.mutationPosition,
        pairType: this.dataForm.add.pairType,
        pairChartOfAccountId: this.dataForm.add.pairChartOfAccountId,
        pairMutationPosition: this.dataForm.add.pairMutationPosition,
        transactionCodeId: this.dataForm.add.transactionCodeId,
        isActive: this.dataForm.add.isActive,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Parameter Ledger Tabungan ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addSavingChartOfAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "saving-chart-of-account",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.resetFormSavingChartOfAccount("ADD");
                    this.getSavingChartOfAccount();
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addSavingChartOfAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async editSavingChartOfAccount() {
      const payload = {
        savingChartOfAccountId: this.identifier.savingChartOfAccountSelected,
        savingId: this.dataForm.edit.savingId,
        chartOfAccountId: this.dataForm.edit.chartOfAccountId,
        productLedgerId: this.dataForm.edit.productLedgerId,
        mutationPosition: this.dataForm.edit.mutationPosition,
        pairChartOfAccountId: this.dataForm.edit.pairChartOfAccountId,
        transactionCodeId: this.dataForm.edit.transactionCodeId,
        pairMutationPosition: this.dataForm.edit.pairMutationPosition,
        isActive: this.dataForm.edit.isActive,
        pairType: this.dataForm.edit.pairType,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Parameter Ledger Tabungan ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editSavingChartOfAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl:
                  "saving-chart-of-account/" +
                  this.identifier.savingChartOfAccountSelected,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.property.modal.showModalEditSavingChartOfAccount = false;
                  setTimeout(() => {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    setTimeout(() => {
                      this.resetFormSavingChartOfAccount("EDIT");
                      this.getSavingChartOfAccount();
                    }, 500);
                  }, 1000);
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editSavingChartOfAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async changePaginationSavingChartOfAccount(event) {
      this.table.data.savingChartOfAccount = [];
      this.property.listElement.savingChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "saving-chart-of-account",
          payload: {
            savingId: this.identity.savingId ? this.identity.savingId : "",
            page: event - 1,
            size: this.property.listElement.savingChartOfAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.savingChartOfAccount = resp.data.data.content;
            this.property.listElement.savingChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.failedGetDataSavingChartOfAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorSavingChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.savingChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    failedGetDataSavingChartOfAccount(resp) {
      this.table.data.savingChartOfAccount = [];
      this.property.listElement.savingChartOfAccount.rows = 0;
      this.property.listElement.savingChartOfAccount.message =
        resp.data.message;
    },
    async deleteSavingChartOfAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan  ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "saving-chart-of-account/" + props.row.savingChartOfAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getSavingChartOfAccount();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    closeModalEditSavingChartOfAccount() {
      this.resetFormSavingChartOfAccount("EDIT");
      this.property.modal.showModalEditSavingChartOfAccount = false;
    },
    changeSelectOptionAkunLedgerAddSavingChartOfAccount() {
      this.dataForm.add.akunLedgerDeskripsi = "";
      this.options.akunLedger.map((i) => {
        if (i.value === this.dataForm.add.chartOfAccountId) {
          this.dataForm.add.akunLedgerDeskripsi = i.description;
        }
      });
    },
    changeSelectAkunLedgerEditSavingChartOfAccount() {
      this.dataForm.edit.akunLedgerDeskripsi = "";
      this.options.akunLedger.map((i) => {
        if (i.value === this.dataForm.edit.chartOfAccountId) {
          this.dataForm.edit.akunLedgerDeskripsi = i.description;
        }
      });
    },
    async getSavingChartOfAccountById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.resetFormSavingChartOfAccount("EDIT");
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl:
              "saving-chart-of-account/" + props.row.savingChartOfAccountId,
          });
          if (resp.data.code === "SUCCESS") {
            this.dataForm.edit.mutationPosition =
              resp.data.data.mutationPosition;
            this.dataForm.edit.productCategoryName = resp.data.data.msaving
              ? resp.data.data.msaving.mproductCategory
                ? resp.data.data.msaving.mproductCategory.productCategoryName
                : ""
              : "";
            this.dataForm.edit.savingId = resp.data.data.msaving
              ? resp.data.data.msaving.savingId
              : "";
            this.dataForm.edit.productLedgerId = resp.data.data.mproductLedger
              ? resp.data.data.mproductLedger.productLedgerId
              : "";
            this.dataForm.edit.isActive = resp.data.data.isActive;
            this.dataForm.edit.chartOfAccountId = resp.data.data.mchartOfAccount
              ? resp.data.data.mchartOfAccount.chartOfAccountId
              : "";
            this.dataForm.edit.akunLedgerDeskripsi = resp.data.data
              .mchartOfAccount
              ? resp.data.data.mchartOfAccount.description
              : "";
            this.dataForm.edit.pairChartOfAccountId = resp.data.data
              .pairMChartOfAccount
              ? resp.data.data.pairMChartOfAccount.chartOfAccountId
              : "";
            this.dataForm.edit.pairMutationPosition = resp.data.data
              .pairMutationPosition
              ? resp.data.data.pairMutationPosition
              : "";
            this.dataForm.edit.transactionCodeId = resp.data.data
              .mtransactionCode
              ? resp.data.data.mtransactionCode.transactionCodeId
              : "";
            this.identifier.savingChartOfAccountSelected =
              resp.data.data.savingChartOfAccountId;
            this.dataForm.edit.pairType = resp.data.data.pairType;
            this.property.modal.showModalEditSavingChartOfAccount = true;
            this.dataForm.edit.createdByUserCode =
              resp.data.data.createdByUserCode;
            this.dataForm.edit.createdByUserName =
              resp.data.data.createdByUserName;
            this.dataForm.edit.createdDate = resp.data.data.createdDate;
            this.dataForm.edit.updatedDate = resp.data.data.updatedDate;
            this.dataForm.edit.updatedByUserCode =
              resp.data.data.updatedByUserCode;
            this.dataForm.edit.updatedByUserName =
              resp.data.data.updatedByUserName;
            this.dataForm.edit.authorizedDate = resp.data.data.authorizedDate;
            this.dataForm.edit.authorizedByUserCode =
              resp.data.data.authorizedByUserCode;
            this.dataForm.edit.authorizedByUserName =
              resp.data.data.authorizedByUserName;
            this.dataForm.edit.isFixedCost = resp.data.data.isFixedCost;
            this.dataForm.edit.fixedCost = resp.data.data.fixedCost;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    resetFormSavingChartOfAccount(type) {
      switch (type) {
        case "ADD":
          this.dataForm.add.productLedgerId = "";
          this.dataForm.add.isActive = false;
          this.dataForm.add.savingId = "";
          this.dataForm.add.chartOfAccountId = "";
          this.dataForm.add.akunLedgerDeskripsi = "";
          this.dataForm.add.mutationPosition = "";
          this.dataForm.add.pairType = "";
          this.dataForm.add.pairChartOfAccountId = "";
          this.dataForm.add.pairMutationPosition = "";
          this.dataForm.add.transactionCodeId = "";
          break;

        case "EDIT":
          this.dataForm.edit.productCategoryName = "";
          this.dataForm.edit.savingId = "";
          this.dataForm.edit.chartOfAccountId = "";
          this.dataForm.edit.productLedgerId = "";
          this.dataForm.edit.akunLedgerDeskripsi = "";
          this.dataForm.edit.isActive = false;
          this.dataForm.edit.mutationPosition = "";
          this.dataForm.edit.pairType = "";
          this.identifier.savingChartOfAccountSelected = "";
          this.dataForm.edit.pairChartOfAccountId = "";
          this.dataForm.edit.pairMutationPosition = "";
          this.dataForm.edit.transactionCodeId = "";
          this.dataForm.edit.createdByUserCode = "";
          this.dataForm.edit.createdByUserName = "";
          this.dataForm.edit.createdDate = "";
          this.dataForm.edit.updatedDate = "";
          this.dataForm.edit.updatedByUserCode = "";
          this.dataForm.edit.updatedByUserName = "";
          this.dataForm.edit.authorizedDate = "";
          this.dataForm.edit.authorizedByUserCode = "";
          this.dataForm.edit.authorizedByUserName = "";
          break;

        default:
          return;
          break;
      }
    },
    appendOptionsPosisiMutasi() {
      this.options.posisiMutasi = [
        { value: "", text: "-- Pilih --" },
        { value: "D", text: "Debet" },
        { value: "C", text: "Credit" },
      ];
    },
  },
  mounted() {
    this.getIdentityFromMixin();
    this.getProductCategoryNameSavingChartOfAccount();
    this.getReferenceProductLedger();
    this.getReferenceChartOfAccount();
    this.getReferenceTransactionCode();
    this.appendOptionsPosisiMutasi();
    this.getSavingChartOfAccount();
  },
};
