import DetailSaving from "./m-saving";
import DetailSavingChartOfAccount from "./m-saving-chart-of-account";
import Mixins from "../Mixins.js";

export default {
  name: "DetailMSaving",
  mixins: [Mixins],
  components: {
    DetailSaving,
    DetailSavingChartOfAccount,
  },
  data() {
    return {
      show: {
        position: {
          order: 0,
          name: "",
        },
        history: [],
        steps: [
          { name: "step1", position: 1 },
          { name: "step2", position: 2 },
        ],
        form: [
          { name: "step1", active: false },
          { name: "step2", active: false },
        ],
      },
    };
  },
  methods: {
    defaultSetupSteper() {
      const index = this.show.form[0];
      index.active = true;

      const findingPostionByName = this.show.steps.find(
        (item) => item.name === index.name
      );
      this.show.position.name = findingPostionByName.name;
      this.show.position.order = findingPostionByName.position;
      this.show.history.push({ name: findingPostionByName.name });
    },
    next() {
      const findPositions = this.show.steps.find(
        (i) => i.position === this.show.position.order + 1
      );
      this.show.form.map((i) => {
        if (i.name === findPositions.name) {
          i.active = true;
        }
      });
      this.show.form.map((item) => {
        if (item.name === this.show.position.name) {
          item.active = false;
        }
      });
      this.show.position.name = findPositions.name;
      this.show.position.order = findPositions.position;
      this.show.history.push({ name: findPositions.name });
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    back() {
      if (this.show.position.order === 1) {
        return;
      } else {
        this.show.form.map((i) => {
          if (i.name === this.show.history[this.show.history.length - 1].name) {
            i.active = false;
          }
        });
        this.show.history.pop();
        this.show.form.map((item) => {
          if (
            item.name === this.show.history[this.show.history.length - 1].name
          ) {
            item.active = true;
          }
        });
        var findingFromStep = this.show.steps.find(
          (i) => i.name === this.show.history[this.show.history.length - 1].name
        );
        this.show.position.name = findingFromStep.name;
        this.show.position.order = findingFromStep.position;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    },
    centralBackToList() {
      this.$router.push("list");
    },
  },
  destroyed() {
    this.$store.dispatch("CLEAR_OPTIONS_SAVING");
  },
  mounted() {
    this.defaultSetupSteper();
  },
};
